import { ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { onChangeProcess, GetSelectedProcess } from '@igps/top-utility';
import { ToastComponent } from '@ui-core/toast';
import { BehaviorSubject, Observable, Subscription, interval } from 'rxjs';
import { StartNewTaskDataModel } from 'src/app/models/startNewTaskDataModel';
import { ProcessorService } from 'src/app/services/processor.service';
import { cardsCountEvent, getCardsCount } from '@igps/dashboard-cards';
import { SourceType } from 'src/app/models/taskSummary';

@Component({
  selector: 'app-process-sub-header',
  templateUrl: './process-sub-header.component.html',
  styleUrls: ['./process-sub-header.component.scss']
})
export class ProcessSubHeaderComponent implements OnInit, OnDestroy {


  @ViewChild(ToastComponent) toaster!: ToastComponent;
  isError!: boolean;
  toasterMessage!: string;
  showSpinner: boolean = false;

  onChangeSubscription!: Subscription;
  processId!: string;
  processName!: string;
  response!: StartNewTaskDataModel;
  emailResponse!: any
  isUserAlreadyAissngedWithTask: boolean = true;
  inProgress: boolean = false;
  isTaskAvailable: boolean = false;
  cardsCountEventSubscription!: Subscription;
  getCardsCountIntervalSubscription!: Subscription;

  buttonDisableSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  buttonDisableObservable: Observable<boolean> = this.buttonDisableSubject.asObservable();
  taskSourceType!: any;

  constructor(
    private processorService: ProcessorService,
    private router: Router,
    private cdref: ChangeDetectorRef, private ngZone: NgZone
  ) { }

  ngOnInit(): void {
    this.intialize();
    const intervalSubscription = interval(500).subscribe(() => {
      if (cardsCountEvent) {
        this.cardsCountEventSubscription = cardsCountEvent.subscribe(cc => {
          this.ngZone.run(() => {
            this.isTaskAvailable = cc.find(c => c.id === 1)!.count > 0 ? true : false;
            this.isUserAlreadyAissngedWithTask = (getCardsCount().find(c => c.id === 0)!.count > 0 && (getCardsCount().find(c => c.id === 5)!.count !== getCardsCount().find(c => c.id === 0)!.count)) ? true : false; // checking hold status
            this.buttonDisableSubject.next((this.isUserAlreadyAissngedWithTask || this.inProgress || !this.isTaskAvailable));
          });
        });
        intervalSubscription.unsubscribe();
      }
    })
    this.getCardsCountIntervalSubscription = interval(1000).subscribe(() => {
      this.checkCardsCount();
    });
  }

  checkCardsCount() {
    this.ngZone.run(() => {
      if (getCardsCount()) {
        this.isTaskAvailable = getCardsCount().find(c => c.id === 1)!.count > 0 ? true : false;
        this.isUserAlreadyAissngedWithTask = (getCardsCount().find(c => c.id === 0)!.count > 0 && (getCardsCount().find(c => c.id === 5)!.count !== getCardsCount().find(c => c.id === 0)!.count)) ? true : false; // checking hold status
        this.buttonDisableSubject.next((this.isUserAlreadyAissngedWithTask || this.inProgress || !this.isTaskAvailable));
        this.cdref.detectChanges();
      }
    });
  }

  intialize() {
    this.onChangeSubscription = onChangeProcess.subscribe((process: {
      taskSource: any; id: string; name: string
    }) => {
      this.ngZone.run(() => {
        this.processId = process.id;
        this.processName = process.name;
        this.taskSourceType = process.taskSource
        this.getUserAssignedStatusToTask();
        this.cdref.detectChanges();
      });
    });
    if (!this.processId) {
      const process = GetSelectedProcess();
      this.ngZone.run(() => {
        if (process?.id) {
          this.processId = process.id;
          this.processName = process.name;
          this.taskSourceType = process.taskSource
          this.getUserAssignedStatusToTask();
          this.cdref.detectChanges();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.onChangeSubscription?.unsubscribe();
    this.cardsCountEventSubscription?.unsubscribe();
    this.getCardsCountIntervalSubscription?.unsubscribe();
  }

  onStartNewTask() {
    if (!this.buttonDisableSubject.value) {
      this.inProgress = true;
      this.buttonDisableSubject.next((this.isUserAlreadyAissngedWithTask || this.inProgress || !this.isTaskAvailable));
      this.ngZone.run(() => {
        if (this.taskSourceType === SourceType.Email) {
          this.showSpinner = true;
          this.processorService.updateStartNewEmail(this.processId).subscribe(res => {
            this.emailResponse = res;
            this.inProgress = false;
            this.buttonDisableSubject.next((this.isUserAlreadyAissngedWithTask || this.inProgress || !this.isTaskAvailable));
            this.cdref.detectChanges();
            if (this.emailResponse.taskId !== null) {
              // redirect to processing screen
              sessionStorage.setItem("selectedEmailNoteTaskId", this.emailResponse.emailMessageId);
              this.router.navigate(['/process/task/', this.emailResponse.taskId, { emailId: this.emailResponse.emailMessageId }], { state: { emailId: this.emailResponse.emailMessageId, emailStatusId: this.emailResponse.statusId, assigneeId: this.emailResponse.assignedTo } });
            } else if (this.emailResponse.emailMessageId !== null) {
              // toast message
              sessionStorage.setItem("selectedEmailNoteTaskId", this.emailResponse.emailMessageId);
              this.router.navigateByUrl('/email-search', { state: { emailId: this.emailResponse.emailMessageId, emailStatusId: this.emailResponse.statusId, assigneeId: this.emailResponse.assignedTo } });

            } else {
              this.showSpinner = false;
              this.openToast(this.emailResponse.message, false)
            }
          })
        } else {
          this.showSpinner = true;
          this.processorService.updateStartNewTask(this.processId).subscribe(res => {
            this.response = res;
            this.inProgress = false;
            this.buttonDisableSubject.next((this.isUserAlreadyAissngedWithTask || this.inProgress || !this.isTaskAvailable));
            this.cdref.detectChanges();
            if (this.response.taskId !== null) {
              // redirect to processing screen
              sessionStorage.removeItem("selectedEmailNoteTaskId");
              this.router.navigate(['/process/task/', this.response.taskId]);
            } else {
              // toast message
              this.showSpinner = false;
              this.openToast(this.response.message, false)
            }
          });
        }

      });
    }
  }

  getUserAssignedStatusToTask() {
    this.ngZone.run(() => {
      if (this.processId !== undefined) {
        this.processorService.getStatusTaskAssignedToUser(this.processId).subscribe(res => {
          this.isUserAlreadyAissngedWithTask = res.isAssigned;
          this.buttonDisableSubject.next((this.isUserAlreadyAissngedWithTask || this.inProgress || !this.isTaskAvailable));
          this.cdref.detectChanges();
        });
      }
    });
  }

  openToast(message: string, isError: boolean) {
    this.isError = isError;
    this.toasterMessage = message;
    this.cardsCountEventSubscription?.unsubscribe();
    this.toaster.toasterTimeout();
  }
}
